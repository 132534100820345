import React, { useState, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/global/layout/Layout';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import ImageWrapper from '../components/global/ImageWrapper/ImageWrapper';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import CustomerHolder from '../components/customers-page/customer-holder/CustomerHolder';
import CustomLink from '../components/global/CustomLink/CustomLink';
import SEO from '../components/global/seo';

import { CONSTANTS } from '../../utils/constants';
import { RenderVideoEmbed } from '../../utils/richtextHelper';
import { mediaQuery, setColors } from '../../utils/helpers';

import { Section } from '../components/home-page/header/styles';
import { UsecaseWrapper } from '../components/bot-builder-page/styles';
import { StyledReactModal } from '../components/newly-developed-pages/style';
import { Container } from '../components/global/customers/styles';
import { CTAWrapper } from '../components/chatbot-analytics/styles';

const HEADER_HEIGHT = 173;
export const customStyles = height => ({
	content: {
		position: 'relative',
		display: 'inline-block',
		margin: 'auto',
		inset: '0',
		boxSizing: 'border-box',
		outline: '0',
		padding: '0',
		border: 'none',
		verticalAlign: 'middle',
		width: '100%',
		maxWidth: '600px',
		height: `${height + HEADER_HEIGHT}px`,
		borderRadius: '10px',
	},
	overlay: {
		backgroundColor: setColors.modalOverLayColor,
		zIndex: '1050',
		overflowX: 'hidden',
		overflowY: 'auto',
		textAlign: 'center',
		padding: '0 6px',
		display: 'flex',
	},
});

const Wrapper = styled.div`
	max-width: 800px;
	padding: 30px 60px 0;
	margin: 0 auto;
	${mediaQuery.mobileXL`padding: 0;
	`}
`;

const FormHolder = styled.div`
	background: ${setColors.white};
	height: ${({ height }) => `${height}px`};
	padding: 20px;
	border-radius: 10px;
`;

const ModalBody = styled.div`
	background: ${setColors.white};
	border-radius: 10px;
	padding: 1px 40px;
`;

const ImageHolder = styled.div`
	cursor: pointer;
	&:focus-visible {
		outline: 2px solid black;
		border-radius: 10px;
	}
`;

const useCaseTemplate = ({ data }) => {
	const {
		seo,
		cta,
		description,
		videoThumbnail,
		section1Title,
		moreUseCases,
		moreUseCasesText,
		useCaseLink,
		modalTitle,
		modalSubtitle,
		video,
		hubspotFormDetails,
	} = data.contentfulPageUseCase;
	const [showVideo, setShowVideo] = useState(false);
	const [showFormPopup, setShowFormPopup] = useState(false);

	const closeModal = useCallback((_, formSubmitted = false) => {
		setShowFormPopup(false);
		setShowVideo(formSubmitted);
	}, []);

	const messageHandler = useCallback(
		event => {
			if (
				event?.data?.eventName === 'onFormSubmit' &&
				event?.data?.id === hubspotFormDetails.formId
			) {
				closeModal(null, true);
			}
		},
		[hubspotFormDetails, closeModal],
	);

	useEffect(() => {
		if (!window.hbspt) {
			const script = document.createElement('script');
			script.src = CONSTANTS.HUBSPOT_FORM_SCRIPT_LINK;
			script.async = true;
			document.body.appendChild(script);
		}
		window.addEventListener('message', messageHandler);
		return () => {
			window.removeEventListener('message', messageHandler);
		};
	}, [messageHandler]);

	const formClickHandler = useCallback(() => {
		setShowFormPopup(true);
		window.hbspt.forms.create({
			region: hubspotFormDetails.region,
			portalId: hubspotFormDetails.portalId,
			formId: hubspotFormDetails.formId,
			target: '#form-holder',
		});
	}, [hubspotFormDetails]);
	const formKeyDownHandler = useCallback(
		event => {
			if (event.key === 'Enter') {
				formClickHandler();
			}
		},
		[formClickHandler],
	);
	return (
		<Layout>
			<StyledReactModal
				isOpen={showFormPopup}
				closeTimeoutMS={500}
				shouldFocusAfterRender={false}
				shouldCloseOnOverlayClick={true}
				style={customStyles(hubspotFormDetails.formHeight)}
				onRequestClose={closeModal}
				portalClassName="portal-class"
				keepMounted
				borderRadius="10px"
				shouldCloseOnEsc={true}
			>
				<div
					className={`modal-content modal-default ${showFormPopup && 'open'}`}
				>
					<button onClick={closeModal} className="close-button">
						×
					</button>
					<ModalBody>
						<SectionTextBox title={modalTitle} subtitle={modalSubtitle} />
						<FormHolder
							height={hubspotFormDetails.formHeight}
							id="form-holder"
						></FormHolder>
					</ModalBody>
				</div>
			</StyledReactModal>
			<SEO {...seo} />
			<Container>
				<Wrapper>
					<div style={{ display: 'grid', placeItems: 'center' }}>
						<Section>
							<h1 dangerouslySetInnerHTML={{ __html: section1Title }}></h1>
						</Section>
					</div>
					{!showVideo ? (
						<ImageHolder
							tabIndex="0"
							role="button"
							onClick={formClickHandler}
							onKeyDown={formKeyDownHandler}
						>
							<ImageWrapper image={videoThumbnail} />
						</ImageHolder>
					) : (
						<RenderVideoEmbed data={video} autoplay={true} />
					)}
					<Section>
						<p>{description.description}</p>
					</Section>
				</Wrapper>
				<WrapperOne>
					<UsecaseWrapper>
						<SectionTextBox
							setHeadersGap={50}
							largeHeading={true}
							title={moreUseCasesText}
						/>
						<CustomerHolder
							customers={moreUseCases}
							isUsecase
							marginEffect={false}
						/>
						<div className="sectionLink">
							<CustomLink link={useCaseLink} />
						</div>
					</UsecaseWrapper>
				</WrapperOne>
				<CTAWrapper>
					<MasterCTA cta={cta} />
				</CTAWrapper>
			</Container>
		</Layout>
	);
};
export const pageQuery = graphql`
	query($slug: String) {
		contentfulPageUseCase(slug: { eq: $slug }) {
			section1Title
			videoThumbnail {
				...asset
			}
			moreUseCasesText
			moreUseCases {
				...useCaseCard
			}
			useCaseLink {
				...links
			}
			description {
				description
			}
			video {
				...videoEmbed
			}
			modalTitle
			modalSubtitle
			hubspotFormDetails {
				region
				portalId
				formId
				formHeight
			}
			cta {
				...cta
			}
			seo {
				...seo
			}
		}
	}
`;
export default useCaseTemplate;
