import { Link } from 'gatsby';
import React from 'react';
import { checkLink } from '../../../../utils/helpers';

const CustomLink = ({ link: { linkUrl, linkText } }) => {
	const urlType = checkLink(linkUrl);
	if (urlType === 2) {
		return <Link to={linkUrl}>{linkText}</Link>;
	} else if (urlType === 1) {
		return (
			<a href={linkUrl} target="_self">
				{linkText}
			</a>
		);
	} else {
		return (
			<a href={linkUrl} target="_blank" rel="noopener noreferrer">
				{linkText}
			</a>
		);
	}
};

export default CustomLink;
